const PACKAGES = Object.freeze( {
	FULL_BODY :
    {
    	name: 'full_body',
    	parts: [
    		{ partCategory: 'Panel', partName: 'Front Bumper' },
    		{ partCategory: 'Panel', partName: 'Hood' },
    		{ partCategory: 'Panel', partName: 'Left Fog Light' },
    		{ partCategory: 'Panel', partName: 'Left Front Door' },
    		{ partCategory: 'Panel', partName: 'Left Front Fender' },
    		{ partCategory: 'Panel', partName: 'Left Rear Door' },
    		{ partCategory: 'Panel', partName: 'Left Rear Fender' },
    		{ partCategory: 'Panel', partName: 'Left Side Mirror' },
    		{ partCategory: 'Panel', partName: 'Lower Grille' },
    		{ partCategory: 'Panel', partName: 'Rear Bumper' },
    		{ partCategory: 'Panel', partName: 'Rear Door' },
    		{ partCategory: 'Panel', partName: 'Right Fog Light' },
    		{ partCategory: 'Panel', partName: 'Right Front Door' },
    		{ partCategory: 'Panel', partName: 'Right Front Fender' },
    		{ partCategory: 'Panel', partName: 'Right Rear Door' },
    		{ partCategory: 'Panel', partName: 'Right Rear Fender' },
    		{ partCategory: 'Panel', partName: 'Right Side Mirror' },
    		{ partCategory: 'Panel', partName: 'Roof' },
    		{ partCategory: 'Light', partName: 'Left Fog Light' },
    		{ partCategory: 'Light', partName: 'Left Head Light' },
    		{ partCategory: 'Light', partName: 'Left Rear Light' },
    		{ partCategory: 'Light', partName: 'Right Fog Light' },
    		{ partCategory: 'Light', partName: 'Right Head Light' },
    		{ partCategory: 'Light', partName: 'Right Rear Light' },
    		// {partCategory:'Panel', partName:''},
    	],
    },
	FULL_FRONTAL : {
		name: 'full_frontal',
		parts: [
			{ partCategory: 'Panel', partName: 'Front Bumper' },
			{ partCategory: 'Panel', partName: 'Hood' },
			{ partCategory: 'Panel', partName: 'Left Fog Light' },
			{ partCategory: 'Panel', partName: 'Left Front Door', edge: true },
			{ partCategory: 'Panel', partName: 'Left Front Fender' },
			{ partCategory: 'Panel', partName: 'Left Rear Door', edge: true },
			{ partCategory: 'Panel', partName: 'Left Rear Fender', partial: true },
			{ partCategory: 'Panel', partName: 'Left Side Mirror' },
			{ partCategory: 'Panel', partName: 'Lower Grille' },
			{ partCategory: 'Panel', partName: 'Right Fog Light' },
			{ partCategory: 'Panel', partName: 'Right Front Door', edge: true },
			{ partCategory: 'Panel', partName: 'Right Rear Door', edge: true },
			{ partCategory: 'Panel', partName: 'Right Front Fender' },
			{ partCategory: 'Panel', partName: 'Right Rear Fender', partial: true },
			{ partCategory: 'Panel', partName: 'Right Side Mirror' },
			{ partCategory: 'Panel', partName: 'Roof', partial: true },
			{ partCategory: 'Light', partName: 'Left Fog Light' },
			{ partCategory: 'Light', partName: 'Left Head Light' },
			{ partCategory: 'Light', partName: 'Right Fog Light' },
			{ partCategory: 'Light', partName: 'Right Head Light' },
			// {partCategory:'Panel', partName:''},
		],
	},
	HALF_QUARTER_HOOD : {
		name: 'half_quarter_hood',
		parts: [
			{ partCategory: 'Panel', partName: 'Front Bumper' },
			{ partCategory: 'Panel', partName: 'Hood', partial: true },
			{ partCategory: 'Panel', partName: 'Left Fog Light' },
			{ partCategory: 'Panel', partName: 'Left Front Door', edge: true },
			{ partCategory: 'Panel', partName: 'Left Front Fender', partial: true },
			{ partCategory: 'Panel', partName: 'Left Rear Door', edge: true },
			{ partCategory: 'Panel', partName: 'Left Rear Fender', partial: true },
			{ partCategory: 'Panel', partName: 'Left Side Mirror' },
			{ partCategory: 'Panel', partName: 'Lower Grille' },
			{ partCategory: 'Panel', partName: 'Right Fog Light' },
			{ partCategory: 'Panel', partName: 'Right Front Door', edge: true },
			{ partCategory: 'Panel', partName: 'Right Rear Door', edge: true },
			{ partCategory: 'Panel', partName: 'Right Front Fender', partial: true},
			{ partCategory: 'Panel', partName: 'Right Rear Fender', partial: true },
			{ partCategory: 'Panel', partName: 'Right Side Mirror' },
			{ partCategory: 'Panel', partName: 'Roof', partial: true },
			{ partCategory: 'Light', partName: 'Left Fog Light' },
			{ partCategory: 'Light', partName: 'Left Head Light' },
			{ partCategory: 'Light', partName: 'Right Fog Light' },
			{ partCategory: 'Light', partName: 'Right Head Light' },
		],
	},
	WINDOW_FILM : {
		name: 'window_film',
		parts: [
			{ partCategory: 'Glass', partName: 'Left Front Door' },
			{ partCategory: 'Glass', partName: 'Left Rear Door' },
			{ partCategory: 'Glass', partName: 'Left Rear Quarter' },
			{ partCategory: 'Glass', partName: 'Rear Gate' },
			{ partCategory: 'Glass', partName: 'Right Front Door' },
			{ partCategory: 'Glass', partName: 'Right Rear Door' },
			{ partCategory: 'Glass', partName: 'Right Rear Quarter' },
		],
	}
} )

const ROLES = Object.freeze( {
	ADMIN: 'admin',
	DEV: 'dev',
	INSTALLER: 'installer',
	SUPERVISOR: 'supervisor',
	CUSTOMER: 'customer',
	GUEST: 'guest'
} )

const USER_STATES = Object.freeze( {
	ALL: 'All',
	ACTIVE: 'Active',
	INACTIVE: 'Inactive'
} )
module.exports = {  PACKAGES, ROLES, USER_STATES }