<template>
  <v-container>
    <div v-for="(item, index) in stockTableItems" :key="index">
      <ServiceStockTable
        :stockTableHeaders="stockTableHeaders"
        :stockTableItems="item"
        @UpdateStockCalculations="UpdateStockCalculations"
      />
    </div>
  </v-container>
</template>
<script>
import { ServiceUtility } from '../../utility/serviceUtilityV2'
import _ from 'lodash'
import ServiceStockTable from './ServiceStockTable.vue'

export default {
	name: 'ServiceStockSelection',
	components: { ServiceStockTable },
	props: ['carConfigs'],
	computed: {},
	created() {
		this.utility = new ServiceUtility()
		this.InitializeData()
	},
	watch: {
		carConfigs: {
			deep: true,
			handler() {
				this.carConfigs.forEach((config) => {})
			},
		},
	},
	data() {
		return {
			utility: '',
			stockTableHeaders: [],
			stockTableItems: [],
		}
	},
	methods: {
		InitializeStockTableConfigs() {
			if (!_.isNil(this.carConfigs)) {
				this.carConfigs.forEach((config) => {
					let tableItemObj = { totalM2WillUse: config.totalM2WillUse }
					if (_.isArray(config.stock) && config.stock.length > 0) {
						tableItemObj.stock = config.stock
						tableItemObj.productName = config.stock[0].productId.name
						tableItemObj.productId = config.stock[0].productId._id
						tableItemObj.selectedStock = config.selectedStock
					}
					this.stockTableItems.push(tableItemObj)
				})
			}
		},
		UpdateStockCalculations(stockData) {
			this.$emit('UpdateStockFromSelection', stockData)
		},

		InitializeData() {
			this.InitializeTableHeaders()
			if (this.carConfigs) {
				this.carConfigs.forEach((config) => {
					if (config.selectedStock == undefined) {
						config.selectedStock = []
					}
					if (!_.isNil(config.stock)) {
						config.stock = config.stock.filter(
							(stock) => stock.length * stock.height - stock.m2Used > 0
						)

						this.utility.UpdateCarConfigCalculations(config)
					}
				})
				this.InitializeStockTableConfigs()
			}
		},

		InitializeTableHeaders() {
			this.partTableHeaders = [
				{ text: 'part', value: 'partName' },
				{ text: 'Product Selection', value: 'productSelection' },
			]

			this.stockTableHeaders = [
				// {text: 'partName', value: 'partname', sortable: false},
				{ text: 'action', value: 'actions', sortable: false },
				// {text: 'stock_name', value: 'productId.name'},
				// {text: 'category', value: 'productId.category'},
				{ text: 'will_use', value: 'm2WillUse' },
				// {text: 'stock_serial', value: 'serial'},
				{ text: 'batch', value: 'batch' },
				// {text: 'totalM2', value: 'totalM2'},
				// {text: 'M2 Used', value: 'm2Used'},
				{ text: 'remaining', value: 'remaining' },
			]
		},
	},
}
</script>
<style>
.table-data {
  text-align: left;
}
.rtl .table-data {
  text-align: right;
}
</style>
