<template>
  <div>
    <v-alert dense outlined type="error" v-if="error">
      Error: {{ error }}
    </v-alert>
    <!--        <p v-if="error">Error: {{error}}</p>-->
    <!--        <p>Result is: {{result}}</p>-->
    <qrcode-stream
      @decode="OnDecode"
      @init="onInit"
      :camera="camera"
      style="max-width: fit-content; margin: auto;"
      
    >
    <div class="qr-loading-overlay" v-if="isLoading">
<h3 class="loading-text">Loading Please wait.</h3>
  <v-progress-circular
      indeterminate
      color="white"
    ></v-progress-circular> 
    </div>
    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
// import {QrcodeStream} from "vue-qrcode-reader";
export default {
	components: { QrcodeStream },
	data() {
		return {
			result: '',
			error: '',
			camera:'auto',
			isLoading:false,
		}
	},
	methods: {
		unpause () {
			this.camera = 'auto'
		},

		pause () {
			this.camera = 'off'
		},
		timeout ( ms ) {
			return new Promise( resolve => {
				window.setTimeout( resolve, ms )
			} )
		},
		async restartCamera(){
			this.pause()
			await this.timeout( 500 )
			this.unpause()
		},
		OnDecode( result ) {
			this.result = result
			this.isLoading= true

			console.log( result )
			if ( result !== null && result !== undefined && result !== '' ) {
				if ( result.includes( 'http' ) ) {
					let splitUrl = result.split( '/' )
					this.result = splitUrl[splitUrl.length - 1]
					console.log( 'result has changed to: ' + this.result )
				}

				this.$emit( 'QrReceived', this.result )
				this.result = null
			} else {
				this.$emit( 'snack-error', 'Error: Not a valid Qr.' )
			}
			this.restartCamera()
			this.isLoading= false
		},
		async onInit( promise ) {
			try {
				await promise
			} catch ( error ) {
				if ( error.name === 'NotAllowedError' ) {
					this.error = 'ERROR: you need to grant camera access permisson'
				} else if ( error.name === 'NotFoundError' ) {
					this.error = 'ERROR: no camera on this device'
				} else if ( error.name === 'NotSupportedError' ) {
					this.error = 'ERROR: secure context required (HTTPS, localhost)'
				} else if ( error.name === 'NotReadableError' ) {
					this.error = 'ERROR: is the camera already in use?'
				} else if ( error.name === 'OverconstrainedError' ) {
					this.error = 'ERROR: installed cameras are not suitable'
				} else if ( error.name === 'StreamApiNotSupportedError' ) {
					this.error = 'ERROR: Stream API is not supported in this browser'
				}
			}
		},
	},
}
</script>

<style scoped>
.qr-loading-overlay{
height: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;  
}
.loading-text{
  color:white;
}
</style>
