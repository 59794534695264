<template>
  <div>
    <v-row style="justify-content: space-evenly">
      <v-btn outlined @click="toggleImage">{{
        showCarImage ? $t("image") : $t("text")
      }}</v-btn>
      <!-- {{selectedParts}}-->
      <!--{{Body.find(part=> part._id ==="5fbb5810ecc6a91bd4932d0e")}}-->
    </v-row>
    <v-row>
      <v-col>
        <v-row align-content="center" justify="center">
          <v-switch
            style="padding: 10px"
            color="red"
            v-model="selectPartial"
            :disabled="selectEdge"
          >
            <template v-slot:label>
              <span :style="selectPartial ? 'color:red' : 'color:lightgray'">{{
                $t("partial")
              }}</span>
            </template>
          </v-switch>
          <v-switch
            style="padding: 10px"
            color="purple"
            v-model="selectEdge"
            :disabled="selectPartial"
          >
            <template v-slot:label>
              <span :style="selectEdge ? 'color:purple' : 'color:lightgray'">{{
                $t("edge")
              }}</span>
            </template>
          </v-switch>
        </v-row>
        <v-col cols="12">
          <v-card
            v-if="!showCarImage"
            v-for="type in GetPartTypes(Body)"
            :key="type"
          >
            <v-card-title>{{ $t(type) }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  :cols="12"
                  cols="xs"
                  v-for="(part, index) in FilterByType(type)"
                  :key="index"
                >
                  <v-checkbox
                    readonly
                    @click="SelectPart(part)"
                    v-model="selectedParts"
                    :value="part"
                    :disabled="
                      isPreviouslySelected(part) || IsCheckDisabled(part)
                    "
                    :label="GetPartLocaleName(part)"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <div
            v-if="showCarImage"
            :class="
              $vuetify.rtl ? 'RTL-FullImageContainer' : 'FullImageContainer'
            "
          >
            <div class="image-container">
              <span
                v-for="(part, index) in Body"
                :key="index"
                @click="SelectPart(part)"
              >
                <label
                  v-if="
                    part.partial &&
                    partialGroup.some((p) => p === part.partName)
                  "
                  :class="GetPartialClassName(part)"
                >
                  1/2</label
                >

                <img
                  :class="GetClass(part)"
                  class="image"
                  :src="GetFullPath(part)"
                />
              </span>
            </div>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {PACKAGES} from '../constants'
export default {
	name: 'CarBody',
	props: [
		'Body',
		'previouslySelected',
		'category',
		'blockWindshield',
		'selectParts',
		'selectedPackage',
	],
	created() {
		// console.log( 'selected packagesssss', this.selectedPackage )
		// console.log( 'BODY', this.Body )
		// const package_body = PACKAGES[this.selectedPackage.toUpperCase()]
		// console.log( 'package', package_body )

		// this.Body.map(part=> part.partial=false)
		if ( this.category && this.category === 'Safety' ) {
			this.SelectPart(
				this.Body.find( ( part ) => part.partName === 'Windshield' )
			)
			this.disableAll = true
		}
		if ( this.selectParts && this.selectParts.length > 0 ) {
			this.selectParts.forEach( ( part ) => {
				this.SelectPart( part )
			} )
		}
	},
	watch: {
		selectedParts: function () {
			this.$emit( 'onSelectionChange', this.selectedParts )
		},
	},
	data() {
		return {
			partialGroup: [
				'Roof',
				'Right Front Fender',
				'Right Rear Fender',
				'Left Front Fender',
				'Left Rear Fender',
				'Hood',
			],
			edgeGroup: [
				'Right Front Door',
				'Right Rear Door',
				'Left Front Door',
				'Left Rear Door',
			],
			disableAll: false,
			selectPartial: false,
			selectEdge: false,
			selectedParts: [],
			partGroup: [
				{
					groupName: 'front',
					parts: [
						'Front Bumper',
						'Lower Grille',
						'Right Fog Light',
						'Grille',
						'Right Head Light',
						'Left Head Light',
					],
				},
			],
			showCarImage: true,
		}
	},
	methods: {
		IsCheckDisabled( part ) {
			if ( this.selectPartial || this.selectEdge ) {
				if (
					this.selectPartial &&
          this.partialGroup.some( ( pg ) => pg === part.partName )
				) {
					return false
				}
				if (
					this.selectEdge &&
          this.edgeGroup.some(
          	( eg ) => eg === part.partName && part.partCategory === 'Panel'
          )
				) {
					return false
				}
				return true
			}
			return false
		},
		ClearSelections() {
			this.selectedParts = []
		},
		toggleImage() {
			this.showCarImage = !this.showCarImage
		},
		isPreviouslySelected( part ) {
			if ( !this.previouslySelected || this.previouslySelected.length == 0 ) {
				return false
			}
			if ( part.partName === 'Windshield' ) {
				if ( !this.blockWindshield ) return false
			}
			return (
				this.previouslySelected.some( ( sPart ) => sPart === part ) &&
        !this.selectedParts.some( ( sp ) => sp === part )
			)
		},
		GetPartLocaleName( part ) {
			let localePartName = 'carParts.' + part.partName.replaceAll( ' ', '_' )
			let translated = `${this.$t( localePartName )} ${
				part.partial ? '(1/2)' : ''
			}  ${part.edge ? '(Edge)' : ''}`
			return translated
		},
		GetFullPath( part ) {
			var images = require.context( '../assets/carParts/', false, /\.png$/ )
			let currentImageName = part.imageName
			if ( part.edge ) {
				let newImageName = part.imageName.split( '.' )
				newImageName[0] = newImageName[0] + ' _edge'
				currentImageName = newImageName.join( '.' )
			}
			if ( this.isPreviouslySelected( part ) ) {
				if (
					this.category &&
          part.partName === 'Windshield' &&
          !this.blockWindshield
				) {
				} else {
					let newImageName = part.imageName.split( '.' )
					newImageName[0] = newImageName[0] + ' _disable'
					currentImageName = newImageName.join( '.' )
					console.log( currentImageName )
				}
			}
			return images( './' + currentImageName )
		},
		SelectPackage( carPackage ) {
			// console.log(carPackage)
			carPackage.parts.forEach( ( part ) => {
				let partToSelect = this.Body.find(
					( p ) =>
						p.partName === part.partName && p.partCategory === part.partCategory
				)
				// console.log(partToSelect)
				this.SelectPart( partToSelect )
			} )
		},
		SelectPart( part ) {
			if (
				!this.selectedParts.some( ( partinarray ) => partinarray._id === part._id )
			) {
				if ( this.selectPartial || part.partial ) {
					part.partial = true
				} else {
					part.partial = false
				}
				if ( this.selectEdge || part.edge ) {
					part.edge = true
				} else {
					part.edge = false
				}
				if ( !this.isPreviouslySelected( part ) ) this.selectedParts.push( part )
			} else {
				part.partial = false
				part.edge = false
				let index = this.selectedParts.indexOf( part )
				this.selectedParts.splice( index, 1 )
			}
		},
		Contain( part ) {
			return this.selectedParts.some(
				( partinarray ) => partinarray._id === part._id
			)
		},
		GetSpecificPart( partCategory, partName ) {
			let part = this.Body.find(
				( bodyPart ) =>
					bodyPart.partName === partName &&
          bodyPart.partCategory === partCategory
			)
			return this.GetFullPath( part.imageName )
		},
		GetPartialClassName( part ) {
			if ( part.partial ) {
				return this.GetPartClassName( part ) + '-partial'
			}
			return ''
		},
		GetPartClassName( part ) {
			return part.partCategory + '-' + part.partName.replaceAll( ' ', '-' )
		},
		GetClass( part ) {
			let selectionClass
			if (
				( this.selectPartial &&
          !this.partialGroup.some( ( p ) => p === part.partName ) ) ||
        ( this.selectEdge &&
          !this.edgeGroup.some(
          	( p ) => p === part.partName && part.partCategory === 'Panel'
          ) )
			) {
				selectionClass = 'hide'
			} else if (
				this.category &&
        !part.productCategory.some( ( cat ) => cat === this.category )
			) {
				selectionClass = 'hide'
			} else if ( this.isPreviouslySelected( part ) ) {
				if ( part.partName === 'Windshield' && !this.blockWindshield ) {
					selectionClass = this.Contain( part ) ? 'selected' : 'unselected'
				} else {
					selectionClass = 'previouslySelected'
				}
			} else {
				selectionClass = this.Contain( part ) ? 'selected' : 'unselected'
			}
			if ( this.category && this.category === 'Safety' ) {
				selectionClass += ' disabled'
			}
			return this.GetPartClassName( part ) + ' ' + selectionClass
		},
		GetPartTypes( parts ) {
			//get all part categories
			let types = parts.map( ( part ) => {
				return part.partCategory
			} )
			//return only unique part categories.
			let filtered = [...new Set( types )]
			return filtered
		},
		FilterByType( type ) {
			let filteredParts = this.Body.filter( ( item ) => {
				return item.partCategory === type
			} )
			// console.log(filteredParts)
			return filteredParts
		},
	},
}
</script>
<style scoped>
* {
  --partial-color: red;
}
.Panel-Hood-partial {
  z-index: 3;
  position: absolute;
  transform: scale(2.5) translate(63px, 114px);
  color: var(--partial-color);
}

.Panel-Roof-partial {
  z-index: 3;
  position: absolute;
  transform: scale(4.5) translate(114px, 62px);
  color: var(--partial-color);
}
.Panel-Left-Front-Fender-partial {
  position: absolute;
  transform: scale(2.5) translate(111px, 204px);
  color: var(--partial-color);
  z-index: 3;
}
.Panel-Left-Rear-Fender-partial {
  position: absolute;
  transform: scale(2.5) translate(294px, 204px);
  color: var(--partial-color);
  z-index: 3;
}
.Panel-Right-Front-Fender-partial {
  position: absolute;
  transform: scale(2.5) translate(111px, 31px);
  color: var(--partial-color);
  z-index: 3;
}
.Panel-Right-Rear-Fender-partial {
  position: absolute;
  transform: scale(2.5) translate(295px, 31px);
  color: var(--partial-color);
  z-index: 3;
}
.disabled {
  pointer-events: none;
}
.hide {
  opacity: 0.3;
  pointer-events: none;
}
.Panel-Front-Bumper {
  position: absolute;
  top: -180px;
  left: -54px;
}
.Panel-Grille {
  position: absolute;
  top: 125px;
  left: 33px;
}
.Panel-Hood {
  /*display:none;*/
  position: absolute;
  left: 28px;
  top: 49px;
}
.Light-Left-Fog-Light {
  position: absolute;
  top: 387px;
  left: 36px;
}
.Panel-Left-Front-Fender {
  position: absolute;
  z-index: 2;
  top: 442px;
  left: 110px;
}
.Panel-Left-Front-Door {
  position: absolute;
  z-index: 2;
  top: 335px;
  left: 243px;
}
.Panel-Left-Rear-Door {
  position: absolute;
  z-index: 2;
  top: 334px;
  left: 415px;
}
.Panel-Left-Rear-Fender {
  position: absolute;
  top: 343px;
  left: 67px;
}
.Panel-Left-Side-Mirror {
  position: absolute;
  z-index: 4;
  top: 460px;
  left: 323px;
}
.Panel-Lower-Grille {
  position: absolute;
  top: 131px;
  left: 17px;
}
.Panel-Rear-Bumper {
  position: absolute;
  top: -250px;
  left: 795px;
}
.Panel-Rear-Door {
  z-index: 0;
  position: absolute;
  top: 16px;
  left: 709px;
}
.Light-Right-Fog-Light {
  position: absolute;
  top: 167px;
  left: 35px;
}
.Panel-Right-Front-Door {
  position: absolute;
  z-index: 2;
  left: 243px;
  top: -88px;
}
.Panel-Right-Front-Fender {
  position: absolute;
  left: 110px;
  top: -52px;
}
.Panel-Right-Rear-Door {
  position: absolute;
  z-index: 2;
  top: -83px;
  left: 416px;
}
.Panel-Right-Rear-Fender {
  position: absolute;
  /*z-index*/
  top: -9px;
  left: 68px;
}

.Panel-Roof {
  /*z-index: 12;*/
  position: absolute;
  /* transform: translate(365px,125px); */
  left: 213px;
  top: 96px;
}
.Panel-Left-Fog-Light {
  position: absolute;
  top: 351px;
  left: 28px;
}
.Panel-Right-Fog-Light {
  position: absolute;
  top: 146px;
  left: 27px;
}
.Light-Left-Head-Light {
  position: absolute;
  top: 337px;
  left: 71px;
}
.Light-Right-Head-Light {
  position: absolute;
  top: 113px;
  left: 71px;
}
.Light-Left-Rear-Light {
  position: absolute;
  left: 741px;
  top: 361px;
}
.Light-Right-Rear-Light {
  position: absolute;
  z-index: 64;
  left: 744px;
  top: 154px;
}
.Glass-Left-Front-Door {
  position: absolute;
  z-index: 3;
  top: 400px;
  left: 271px;
}
.Glass-Left-Rear-Door {
  position: absolute;
  z-index: 3;
  top: 404px;
  left: 455px;
}
.Glass-Left-Rear-Quarter {
  position: absolute;
  z-index: 3;
  top: 408px;
  left: 596px;
}
.Glass-Rear-Gate {
  position: absolute;
  left: 667px;
  top: 113px;
}
.Glass-Right-Front-Door {
  position: absolute;
  z-index: 3;
  top: 89px;
  left: 269px;
}
.Panel-Right-Side-Mirror {
  position: absolute;
  z-index: 4;
  top: 89px;
  left: 320px;
}
.Glass-Right-Rear-Door {
  position: absolute;
  z-index: 3;
  top: 106px;
  left: 458px;
}
.Glass-Right-Rear-Quarter {
  position: absolute;
  z-index: 3;
  top: 116px;
  left: 598px;
}
.Glass-Windshield {
  position: absolute;
  left: 137px;
  top: 67px;
}
.previouslySelected {
  pointer-events: none;
}
.unselected {
  opacity: 0.3;
  filter: brightness(30%);
}
.selected {
  position: absolute;

  opacity: 1;
  filter: brightness(150%);
}
.image {
  transform: scale(0.5);
  height: auto;
}
.image:hover {
  filter: brightness(150%) contrast(150%);
  opacity: 1;
  background: green;
  /*filter: contrast(-50%);*/
}
.image-container {
  position: relative;
  display: flex;
  width: 100%;
}
.FullImageContainer {
  transform-origin: top;
  display: flex;
  transform: scale(0.5); /*translate(28vw,-50%);*/
  position: relative;
  width: max-content;
  height: 40vh;
  width: auto;
}
.RTL-FullImageContainer {
  transform-origin: top;
  display: flex;
  transform: scale(0.5); /* translate(-103vw,-50%);*/
  position: relative;
  width: max-content;
  height: 40vh;
  width: auto;
}
@media (min-width: 200px) and (max-width: 500px) {
  .FullImageContainer {
    transform-origin: top center;
    transform: scale(0.3) rotate(90deg) translate(23vh, -78vw);
  }
  .RTL-FullImageContainer {
    transform-origin: top center;
    transform: scale(0.3) rotate(90deg) translate(23vh, -78vw);
  }
  .image {
    position: absolute;

    height: auto;
  }
}
@media (min-width: 501px) and (max-width: 800px) {
  .FullImageContainer {
    transform-origin: top center;
    transform: scale(0.3) translate(-48vw, 5vw);
    width: auto;
  }
  .RTL-FullImageContainer {
    transform-origin: top center;
    transform: scale(0.3); /*translate(9vw,12%) ;*/
    width: auto;
  }
  .image {
    position: absolute;

    height: auto;
  }
}
/* @media(min-width:1910px) and (max-width:4021px) {
        .FullImageContainer{
            transform-origin: top center;
            transform: scale(0.5) translate(50vw,0vw) ;
        }
        .RTL-FullImageContainer{
            transform-origin: top center;
            transform: scale(0.5)  translate(-100vw,0vh) ;
        }
        .image{
            position: absolute;

            height: auto;
        ;
        }
    } */
/* @media (min-width: 200px) and (max-width:420px) {
        .FullImageContainer{
            transform-origin: top center;
            transform: scale(0.3) rotate(90deg) translate(21vw,-180vw) ;
        }
        .RTL-FullImageContainer{
            transform-origin: top center;
            transform: scale(0.3) rotate(90deg) translate(9vw,12%) ;
        }
        .image{
            position: absolute;

            height: auto;
            ;
        }
    } */
</style>
