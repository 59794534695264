<template>
  <v-container style="min-width: fit-content">
    <v-card style="margin: auto" class="text-center justify-center">
      <v-card-title class="text-center justify-center"
        ><h2>{{ $t("login") }}</h2></v-card-title
      >
      <v-card-text class="">
        <v-tabs v-model="currentTab" centered>
          <v-tab v-for="(tab, index) in tabs" :key="index">
            <v-icon>{{ tab.icon }}</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="currentTab">
          <v-tab-item v-for="(tab, index) in tabs" :key="index">
            <component
              :is="tab.component"
              @QrReceived="TryGetCustomer"
            ></component>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import Login from './Login'
import QR from '../components/QR'
import axios from 'axios'
import { bus } from '../main'
export default {
	name: 'LandingPage',
	components: { Login, QR },
	computed: {
		Props: function () {
			let tab = this.tabs[this.currentTab]
			// console.log(tab)
			if (tab && tab.component === 'QR') {
				// console.log("Splash selected")
				// return {"@QrReceived='TryGetCustomer'"}
			} else if (currentTab && currentTab.component == 'WorkHistoryChart') {
				return { chartData: this.chartData }
			}
		},
	},
	data() {
		return {
			loading: false,
			tabs: [
				{ text: 'staff', component: 'Login', icon: 'mdi-account-tie-outline' },
				{ text: 'QR', component: 'QR', icon: 'mdi-qrcode-scan' },
			],
			currentTab: '',
		}
	},
	methods: {
		async TryGetCustomer(qrString) {
			if (qrString !== null || qrString !== '') {
				console.log('customer: ' + qrString)
				try {
					console.log(`trying to get customer with qr: ${qrString}`)

					let customerResponse = await axios.get(
						process.env.VUE_APP_BASEURL + 'customer/qr/' + qrString
					)
					let customer = customerResponse.data
					console.log(customerResponse)
					if (customer) {
						await this.$router.push('WorkHistoryView/' + customer._id)
						this.loading = false
					} else {
						bus.$emit('snack-error', 'User not found from QR')
						this.loading = false
					}
				} catch (e) {
					this.loading = false
					this.currentTab = 0
					bus.$emit('snack-error', 'User not found from Qr')
					console.log(e)
				}
			}
		},
	},
}
</script>

<style scoped>
.bg {
  background-image: url("../assets/thenanogard.png");
}
</style>
