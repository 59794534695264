<template>
  <v-container>
    <v-dialog
      v-model="showSplash"
      overlay-color="black"
      overlay-opacity="1"
      persistent
      max-width="400px"
      transition="fade-transition"
    >
      <v-img src="../assets/logo-white.png" />
    </v-dialog>
  </v-container>
</template>
<script>
export default {
	name: 'Splash',
	created() {
		this.DelayCloseDialog()
	},
	props: ['showSplash'],
	data() {
		return {
			splash: true,
		}
	},
	methods: {
		DelayCloseDialog() {
			setTimeout(() => {
				this.splash = false
			}, 2000)
		},
	},
}
</script>
