<template>
  <v-container>
    <!--        <v-btn @click="GetInstallerServiceWork()">GetServiceWork</v-btn>-->
    <v-layout align-center justify-center>
      <h1>{{ $t("welcome") }} {{ installerName }}</h1>
    </v-layout>
    <v-row class=" pa-3">
      <v-col cols="12" md="4">
        <v-skeleton-loader
          v-if="loading_dashData"
          type="image"
          :max-width="cardWidth"
          min-height="100%"
          min-width="fit-content"
          max-height="fit-content"
          class="ma-auto"
        ></v-skeleton-loader>
        <v-card
          v-else
          color="green"
          style="color: white"
          :max-width="cardWidth"
          min-height="100%"
          min-width="fit-content"
          max-height="fit-content"
          class="ma-auto"
        >
          <v-card-title class="justify-center">{{
            $t("in_stock")
          }}</v-card-title>
          <v-card-text
            style="color: white; font-size: xx-large"
            class="text-center"
            ><b>{{ GetTotalUseableStock() }}</b></v-card-text
          >
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-skeleton-loader
          v-if="loading_dashData"
          type="image"
          min-height="100%"
          min-width="fit-content"
          max-height="fit-content"
          :max-width="cardWidth"
          class="ma-auto"
        ></v-skeleton-loader>
        <v-card
          v-else
          color="orange"
          style="color: white"
          :max-width="cardWidth"
          min-height="100%"
          min-width="fit-content"
          max-height="fit-content"
          class="ma-auto"
        >
          <v-card-title class="justify-center">{{
            $t("services_this_month")
          }}</v-card-title>
          <v-card-text
            style="color: white; font-size: xx-large"
            class="text-center"
            >{{ dashData.thisMonthCount }}</v-card-text
          >
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-skeleton-loader
          v-if="loading_dashData"
          type="image"
          min-height="100%"
          min-width="fit-content"
          max-height="fit-content"
          :max-width="cardWidth"
          class="ma-auto"
        ></v-skeleton-loader>
        <v-card
          v-else
          color="blue"
          style="color: white"
          :max-width="cardWidth"
          min-height="100%"
          min-width="fit-content"
          max-height="fit-content"
          class="ma-auto"
        >
          <v-card-title class="justify-center">{{
            $t("total_service_done")
          }}</v-card-title>
          <v-card-text
            style="color: white; font-size: xx-large"
            class="text-center"
            >{{ dashData.totalCount }}</v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-container style="border: 1px solid black; height: 100%;">
			<v-row>
				<h3 class="card-header">{{ $t("products_in_stock") }}</h3>

			</v-row>
          <v-row>
            <v-col>
              <v-select
                outlined
                :label="$t('filter')"
                style="max-width: fit-content"
                v-model="selectedFilterStock"
                :items="filterStock"
              >
                <template v-slot:item="{ item }">
                  <span>{{ $t(item) }}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <span>{{ $t(item) }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col>
              <v-select
                outlined
                :label="$t('category')"
                style="max-width: fit-content"
                v-model="selectedFilterCategory"
                :items="filterCategory"
                item-text="item"
              >
                <template v-slot:item="{ item }">
                  <span>{{ $t(item) }}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <span>{{ $t(item) }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-data-table
            title="Products in stock"
            :items="installerStocksTableData"
            :item-class="GetRowClass"
            :headers="localizedStockTableHeaders"
            :loading="loading_installerStocks"
            :expanded.sync="productsExpanded"
			:sort-desc="[true]"
          >
            <template v-slot:top="{ item }">
              <v-checkbox label="Group" v-model="groupByCheck"></v-checkbox> 
            </template>
            <template
              v-slot:group.header="{
                group,
                headers,
                toggle,
                isOpen,
                items,
                groupBy,
                remove,
              }"
            >
              <td
                style="
                  color: white;
                  font-size: x-large;
                  cursor: pointer;
                "
                :colspan="3"
                @click="toggle"
              >
                <b> {{ group }} ({{ items.length + 1 }})</b>
              </td>
              <td
                style="
                  color: white;
                  font-size: x-large;
                  cursor: pointer;
                  text-align: center;
                "
				@click="toggle"
              >
                {{ isOpen ? "-" : "+" }}
              </td>
            </template>

            <template v-slot:[`item.dateAssigned`]="{ item }">
              {{ FormatDate(item.dateAssigned) }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
				<v-chip> {{ $t("length") }}: {{ item.lengthUsed }} </v-chip>
				<v-chip> {{ $t("height") }}: {{ item.heightUsed }} </v-chip>
            </template>
			<template v-slot:[`item.expired`]="{ item }">
				<span :style="item.isExpired ? 'color:red' : 'color:green'">{{ item.isExpired ? $t('expired') : $t('not_expired') }}</span>
				</template>
          </v-data-table>
        </v-container>
      </v-col>
      <v-col>
        <v-container style="border: 1px solid black; height: 100%;">
          <h3 class="pa-3" style="background-color: #616161;">{{ $t("latest_service_done") }}</h3>
          <v-data-table
            :items="dashData.lastServiceWorks"
            :headers="localizedServiceWorkTableHeaders"
            :loading="loading_dashData"
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ FormatDate(item.createdAt) }}
            </template>
            <template v-slot:[`item.customerId.cars`]="{ item }">
              {{ GetCurrentCarName(item) }}
              <!--                            {{item.cars.filter(car=> car._id===item.carId).make}}-->
            </template>
			<!-- <template v-slot:[`item.expired`]="{ item }">
				<span :style="item.isExpired ? 'color:red' : 'color:green'">{{ item.isExpired ? $t('expired') : $t('not_expired') }}</span>
				</template> -->
          </v-data-table>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServiceInstaller from '../components/ServiceInstaller'
import axios from 'axios'
import { bus } from '../main'
export default {
	name: 'InstallerDash',
	components: { ServiceInstaller },
	watch: {
		'$i18n.locale': function( newLocale, oldLocale ) {
			if ( newLocale === oldLocale ) {
				return
			}
			this.GetHeaderNames()
		},
		groupByCheck: function( value ) {
			if ( value ) {
				this.stockGroupBy = 'productId.name'
			} else {
				this.stockGroupBy = null
			}
		},
		selectedFilterCategory: function( value ) {
			this.Filter()
		},
		selectedFilterStock: function( value ) {
			this.Filter()
		},
	},
	created() {
		this.GetHeaderNames()
		this.GetInstallerName()
		// this.GetInstallerStock()
		this.GetInstallerDashData()
	},
	data() {
		return {
			groupByCheck: false,
			stockGroupBy: 'productId.name',
			productsExpanded: [],
			dashData: '',
			cardWidth: '40vh',
			selectedFilterStock: 'active',
			filterStock: ['all', 'active', 'used', 'unused', 'finished'],
			selectedFilterCategory: 'All',
			filterCategory: ['all', 'PPF', 'window_film', 'safety'],
			stockTableHeaders: [
				{
					text: 'date',
					value: 'dateAssigned',
					class: 'header-class',
					divider: true,
				},
				{
					text: 'stock_name',
					value: 'productId.name',
					class: 'header-class',
					divider: true,
				},
				{
					text: 'category',
					value: 'productId.category',
					class: 'header-class',
					divider: true,
				},
				{ text: 'batch', value: 'batch', class: 'header-class', divider: true },
				{text: 'expired', value: 'expired', class: 'header-class', divider: true},
				// {text: 'stock_serial', value:'serial', class:"header-class", divider:true},
				// {text: 'amount_used', value:'amount'},
				// 'stock_name','stock_serial','batch'
			],
			serviceWorkTableHeaders: [
				{
					text: 'date',
					value: 'createdAt',
					class: 'header-class',
					divider: true,
				},
				{
					text: 'name',
					value: 'customerId.name',
					class: 'header-class',
					divider: true,
				},
				{
					text: 'car',
					value: 'customerId.cars',
					class: 'header-class',
					divider: true,
				},
				{
					text: 'phone',
					value: 'customerId.phone',
					class: 'header-class',
					divider: true,
				},
			],
			localizedStockTableHeaders: '',
			localizedServiceWorkTableHeaders: '',
			showSplash: Boolean,
			installer: '',
			installerName: '',
			installerStocks: [],
			installerStocksTableData: [],
			loading_installerStocks: Boolean,
			loading_dashData: true,
		}
	},
	methods: {
		Filter() {
			// console.log( 'filtering ', this.installerStocksTableData )
			//first filter by stock status, then check and filter by category.
			if ( this.selectedFilterStock === 'all' ) {
				this.installerStocksTableData = this.installerStocks
				this.installerStocksTableData.sort( ( a, b ) => {
					if ( this.IsStockFinished( a ) ) {
						return 1
					}
					if ( this.IsStockFinished( b ) ) {
						return -1
					}
					return 0
				} )
			} else if ( this.selectedFilterStock === 'active' ) {
				this.installerStocksTableData = this.installerStocks.filter(
					( stock ) => stock.m2Used < stock.length * stock.height
				)
			} else if ( this.selectedFilterStock === 'used' ) {
				this.installerStocksTableData = this.installerStocks.filter(
					( stock ) => stock.m2Used > 0 && !this.IsStockFinished( stock )
				)
			} else if ( this.selectedFilterStock === 'unused' ) {
				this.installerStocksTableData = this.installerStocks.filter(
					( stock ) => stock.m2Used === 0
				)
			} else if ( this.selectedFilterStock === 'finished' ) {
				this.installerStocksTableData = this.installerStocks.filter(
					( stock ) => stock.m2Used >= stock.length * stock.height
				)
			}

			//then filter by category
			if ( this.selectedFilterCategory === 'All' ) {
				return
				// console.log( this.installerStocks )
				// this.installerStocksTableData = this.installerStocksTableData
			} else if ( this.selectedFilterCategory === 'PPF' ) {
				this.installerStocksTableData = this.installerStocksTableData.filter(
					( stock ) => stock.productId.category === 'PPF'
				)
			} else if ( this.selectedFilterCategory === 'window_film' ) {
				this.installerStocksTableData = this.installerStocksTableData.filter(
					( stock ) => stock.productId.category === 'Window Film'
				)
			} else if ( this.selectedFilterCategory === 'safety' ) {
				this.installerStocksTableData = this.installerStocksTableData.filter(
					( stock ) => stock.productId.category === 'Safety'
				)
			}
		},
		GetCurrentCarName( item ) {
			try{
				const selectedCar = item.customerId.cars.find(
					( car ) => car._id === item.carId
				)
				if( !selectedCar ){
					return 'N/A'
				}
				return selectedCar.make + ' ' + selectedCar.model

			}
			catch( e ){
				console.log( 'Error getting car name from : ', item )
				console.log( e )
			}
			// console.log(customer);
		},
		GetTotalUseableStock() {
			return this.installerStocks.filter(
				( stock ) => !this.IsStockFinished( stock )
			).length
		},
		GetRowClass( item ) {
			if ( this.IsStockFinished( item ) ) {
				return 'finishedStock'
			}
		},
		IsStockFinished( item ) {
			return item.m2Used >= item.length * item.height
		},
		GetHeaderNames() {
			this.localizedStockTableHeaders = JSON.parse(
				JSON.stringify( this.stockTableHeaders )
			)

			for ( let i = 0; i < this.localizedStockTableHeaders.length; i++ ) {
				this.localizedStockTableHeaders[i].text = this.$t(
					this.localizedStockTableHeaders[i].text
				)
			}
			this.localizedServiceWorkTableHeaders = JSON.parse(
				JSON.stringify( this.serviceWorkTableHeaders )
			)
			this.localizedServiceWorkTableHeaders.forEach(
				( item ) => ( item.text = this.$t( item.text ) )
			)
		},
		GetInstallerName() {
			this.installer = this.$store.getters.getUser
			this.installerName = this.installer.name
		},
		// async GetInstallerStock() {
		// 	try {
		// 		if ( this.installer ) {
		// 			this.loading_installerStocks = true
		// 			let stockResponse = await axios.get(
		// 				process.env.VUE_APP_BASEURL +
		//       'stock/installerStock/' +
		//       this.installer._id
		// 			)
		// 			this.installerStocks = stockResponse.data
		// 			//set tabledata to active stocks initially
		// 			this.installerStocksTableData = this.installerStocks.filter(
		// 				( stock ) => stock.m2Used < stock.length * stock.height
		// 			)
					
		// 			this.loading_installerStocks = false
		// 		}
		// 	} catch ( e ) {
		// 		this.loading_installerStocks = false
		// 		bus.$emit( 'snack-error', 'Stock not received.' )
		// 	}
		// },
		async GetInstallerDashData() {
			this.loading_installerStocks = true

			let serviceWorkResponse = await axios.get(
				process.env.VUE_APP_BASEURL +
          'dashboard/installer/' +
          this.installer._id 
			)
			this.dashData = serviceWorkResponse.data
			this.loading_dashData = false
			console.log( this.dashData )
			this.installerStocksTableData = this.dashData.stocks
			this.loading_installerStocks = false
		},
		FormatDate( date ) {
			return new Date( date ).toLocaleDateString(
				this.$i18n.locale === 'en' ? 'eng-us' : 'ara-kw',
				{ year: 'numeric', month: 'long', day: 'numeric' }
			)
		},
	},
}
</script>
<style>
.finishedStock {
  color: lightgray;
  /* background-color:red; */
}
.header-class {
  text-align-last: center;
  vertical-align: center;
}
.card-header{
	background-color: #616161;
	width: 100%;
	padding: 0.5em;
}
</style>
