<template>
  <v-dialog
    v-model="dialog"
    max-width="fit-content"
    max-height="600"
    :width="width ? width : ''"
  >
    <!-- Dialog: {{dialog}} -->
    <v-card
      :width="width ? width : ''"
      :min-width="minWidth ? minWidth : ''"
      :max-width="maxWidth ? maxWidth : ''"
    >
      <v-card-title
        style="background-color: black; color: white; height: fit-content"
        class="justify-center"
      >
        <h2><slot name="title"></slot></h2>
      </v-card-title>

      <v-card-text class="pa-4">
        <slot name="text"></slot>
      </v-card-text>
      <v-card-actions >
        <!-- <actions> -->
        <v-spacer></v-spacer>

        <slot name="actions">
          <slot name="confirm"> </slot>
          <v-btn v-if="!hideCancel" @click="$emit('update:showDialog', (dialog = false))"
            >Cancel</v-btn
          >
        </slot>
      </v-card-actions>
      <!-- </actions> -->
    </v-card>
  </v-dialog>
</template>
<script>
export default {
	name: 'DialogCard',
	props: ['showDialog', 'width', 'minWidth', 'maxWidth', 'hideCancel'],
	data() {
		return {
			dialog: Boolean,
		}
	},
	watch: {
		dialog: function( value ) {
			if ( this.showDialog !== value ) {
				this.$emit( 'update:showDialog', value )
			}
		},
		showDialog: function( value ) {
			if ( this.dialog !== value ) {
				this.dialog = value
			}
		},
	},
	created() {
		this.dialog = this.showDialog
	},
}
</script>
